import React, { useEffect, useState, useRef } from "react";
import Footer from "../Components/HomeSections/Footer";
import FilterHeader from "../Components/PropertyFilters/FilterHeader";
import NavBar from "../Components/HomeSections/NavBar";
import "../Style/Profile.css";
import { userinfoAPI, UpDateInfo } from "../API/ProfileApi";
import { ProfileChange } from "../API/ProfileApi";
import Changepasswordmodel from "../Auth/Changepasswordmodel";
import { logOutapi } from "../API/AuthAPI";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { ErrorToaster } from "../Toster";

const Profile = () => {
  const [loding, setLoding] = useState(false);
  const navigation = useNavigate();
  const inputRef = useRef(null);
  const [profileImage, setProfileImage] = useState();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  let token = localStorage.getItem("Henneberry");

  useEffect(() => {
    userInfo();
  }, [token]);

  const userInfo = () => {
    setLoding(true);
    userinfoAPI(token, setLoding, navigation, (callback) => {
      setProfileImage(callback?.data?.user_record[0]?.image);
      setName(callback?.data?.user_record[0]?.name);
      setPhone(callback?.data?.user_record[0]?.contact_number);
      setEmail(callback?.data?.user_record[0]?.email);
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (phone.length < 9 || phone.length > 12) {
      ErrorToaster("phone number less than 12 and greater than 9");
    } else {
      let formdata = {
        name: name,
        contact_number: phone,
      };
      UpDateInfo(token, formdata);
    }
  };
  const handleImage = (e) => {
    const file = e.target.files[0];

    var render = new FileReader();
    render.readAsDataURL(file);
    render.onload = () => {
      let profile = {
        image: render.result,
      };

      ProfileChange(token, profile, (callback) => {
        setProfileImage(callback);
      });
    };
    render.onerror = (error) => {};
  };
  const handleUpdateImage = () => {
    inputRef.current.click();
  };
  const handlelogout = () => {
    logOutapi(token, navigation);
  };
  return (
    <>
      <div className="filterbanner_bg">
        <NavBar />
        <FilterHeader />
      </div>
      <div className="container">
        {loding ? (
          <div className="d-flex align-items-center justify-content-center">
            <Loader />
          </div>
        ) : (
          <div className="mt-5 mb-5">
            <div className="row" style={{ width: "100%" }}>
              <div className="d-flex align-items-center justify-content-center flex-column">
                <div className="profile-left">
                  <img src={profileImage} alt="profileimg" />
                </div>
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{
                    width: "200px",
                    marginTop: 10,
                    backgroundColor: "rgba(203, 188, 160, 0.8)",
                    color: "black",
                    border: "none",
                  }}
                  onClick={handleUpdateImage}
                >
                  Edit Profile
                </button>
                <input
                  type="file"
                  accept="image/png,image/jpg,image/jpeg,"
                  ref={inputRef}
                  onChange={handleImage}
                  style={{ display: "none" }}
                />
              </div>
              <div className="mt-5">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label">Name</label>

                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email</label>

                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Phone Number</label>

                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      backgroundColor: "rgba(203, 188, 160, 0.8)",
                      color: "black",
                      border: "none",
                    }}
                  >
                    Update
                  </button>
                </form>
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      backgroundColor: "black",
                      color: "white",
                      border: "none",
                    }}
                    onClick={() => setShow(!show)}
                  >
                    Change Password
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{
                      width: "100%",
                      marginTop: 10,
                      backgroundColor: "red",
                      color: "black",
                      border: "none",
                    }}
                    onClick={handlelogout}
                  >
                    LogOut
                  </button>
                </div>
              </div>
            </div>
            {show ? <Changepasswordmodel show={show} setShow={setShow} /> : ""}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Profile;
