import React, { useEffect } from "react";
import NavBar from "../Components/HomeSections/NavBar";
import Header from "../Components/HomeSections/Header";
import "../../src/Style/Header.css";
import HomePageListing from "../Components/HomeSections/HomePageListing";
import HomeAboutUs from "../Components/HomeSections/HomeAboutUs";
import Result from "../Components/HomeSections/Result";
import HomeBlog from "../Components/HomeSections/HomeBlog";
import SecondBanner from "../Components/HomeSections/SecondBanner";
import Listingproperty from "../Components/HomeSections/Listingproperty";
import PopularProperty from "../Components/HomeSections/PopularProperty";
import Footer from "../Components/HomeSections/Footer";
import ContactForm from "../Components/HomeSections/ContactForm";
import Homepropertylisting from "../Components/HomeSections/Homepropertylisting";
import videoBg from "../video/videoplayback.mp4";
import video from "../video/bg.mp4";
import Testimonial from "../Components/HomeSections/Testimonial";
import OurSupport from "../Components/HomeSections/OurSupport";

////////// for speed dialer

import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { green, orange, blue } from "@mui/material/colors";

import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from "@mui/icons-material/Send";

const outerTheme = createTheme({
  palette: {
    secondary: {
      main: blue[500],
    },
  },
});

const websiteUrl = "https://www.dihenneberry.com.au/";

const actions = [
  { 
    icon: <FacebookIcon />, 
    name: "Facebook", 
    link: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(websiteUrl)}`
  },
  { 
    icon: <InstagramIcon />, 
    name: "Instagram", 
    link: `https://www.instagram.com/?url=${encodeURIComponent(websiteUrl)}`
  },
  { 
    icon: <XIcon />, 
    name: "Twitter", 
    link: `https://twitter.com/intent/tweet?url=${encodeURIComponent(websiteUrl)}`
  },
  { 
    icon: <LinkedInIcon />, 
    name: "LinkedIn", 
    link: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(websiteUrl)}`
  },
  { 
    icon: <WhatsAppIcon />, 
    name: "WhatsApp", 
    link: `https://api.whatsapp.com/send?text=${encodeURIComponent(websiteUrl)}`
  }
];

const Home = () => {
  const listingData = [
    { name: "Acquired", key: "6", slug: "acquired" },
    { name: "For Sale", key: "1", slug: "for-sale" },
    { name: "Sold", key: "5", slug: "sold" },
  ];
  const listingData2 = [
    { name: "For Lease", key: "2", slug: "for-lease" },
    { name: "Leased ", key: "4", slug: "leased" },
    { name: "Executive Leasing", key: "7", slug: "executive-leasing" },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="position-relative home_top_main">
        <ThemeProvider theme={outerTheme}>
          <Box className="speed_dialer">
            <SpeedDial
              ariaLabel="SpeedDial basic example"
              icon={<SendIcon className="send_icon_speed_dialer" />}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  className="tooltip_icons_speed_dailer"
                  sx={{ color: "#01153e", width: 60, height: 60 }}
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={() =>
                    window.open(
                      `${action.link}`,
                      "_blank",
                      "rel=noopener noreferrer"
                    )
                  }
                />
              ))}
            </SpeedDial>
          </Box>
        </ThemeProvider>

        {/* <div className="banner_bg">
          <NavBar />
          <Header/>
        </div>  */}
        <div>
          <div className="video-overlay"></div>
          <video className="videoTag" autoPlay loop muted>
            <source src={video} type="video/mp4" />
          </video>

          <div style={{ position: "absolute", top: 0, width: "100%" }}>
            <NavBar />
          </div>
          <div className="videoheader"></div>
        </div>
        <HomePageListing listingData={listingData} />
        <div style={{ backgroundColor: "#F6F8FF" }}>
          {/* <HomePageListing listingData={listingData2}/> */}
          <Homepropertylisting listingData={listingData2} />
        </div>
        <HomeAboutUs />
        <Result />
        <HomeBlog />
        <SecondBanner />
        {/* <Listingproperty /> */}
        <PopularProperty />
        <Testimonial />
        <ContactForm />
        <OurSupport />
        <Footer />
      </div>
    </>
  );
};

export default Home;
